<template>
  <span>{{minute}}:{{second}}</span>
</template>
<script>
  export default {
     name: 'Countdown',
    data() {
      return {
        timer: null,
        day: '--',
        hour: '--',
        minute: '--',
        second: '--',
        millisecond: '--',
        endTime: 0
      };
    },
    beforeDestroy() {
      this.clearTimer();
    },
    methods: {
      start(endTime) {
        this.endTime = endTime || 0;
        this.timer = setInterval(this.countdown, 1000);
      },
      updateEndTime(endTime) {
        this.endTime = endTime;
      },
      countdown() {
        this.endTime -= 1000;
        if (this.endTime <= 0) {
          this.clearTimer();
          this.$emit('finish');
          return false;
        }

        const oneHour = 60 * 60;
        const oneDay = oneHour * 24;
        const s = parseInt(this.endTime / 1000);
        const ms = this.endTime % 1000;

        this.day = this.complement(parseInt(s / oneDay));
        this.hour = this.complement(parseInt(s % oneDay / oneHour));
        this.minute = this.complement(parseInt(s % oneDay % oneHour / 60));
        this.second = this.complement(parseInt(s % oneDay % oneHour % 60));
        this.millisecond = ms > 100 ? (ms + '').substring(0, 1) : 0;
      },
      complement(num) {
        return num < 10 ? '0' + num : num;
      },
      clearTimer() {
        clearInterval(this.timer);
      }
    }
  };
</script>
