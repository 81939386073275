<template>
  <swiper class="precautions" :options="swiperOption">
    <!--费用细节-->
    <swiper-slide v-if="cost">
      <div class="h3">{{$t('CostDetails')}}</div>
      <template v-if="cost&&cost.includeContent">
        <div class="b">{{$t('CostIncludes')}}：</div>
        <div class="m-t" v-html="formatTextareaContent(cost.includeContent)"></div>
      </template>
      <template v-if="cost&&cost.notIncludeContent">
        <div class="m-t b">{{$t('CostDoesNotInclude')}}：</div>
        <div class="m-t" v-html="formatTextareaContent(cost.notIncludeContent)"></div>
      </template>
    </swiper-slide>
    <!--预订须知-->
    <swiper-slide v-if="programLabels.includes('booking')">
      <div class="h3">{{$t('BookingInstructions')}}</div>
      <div class="rich-text" v-html="booking.content"></div>
    </swiper-slide>
    <!--责任条款-->
    <swiper-slide v-if="programLabels.includes('responsibility')">
      <div class="h3">{{$t('LiabilityClause')}}</div>
      <div class="rich-text" v-html="responsibility.content"></div>
    </swiper-slide>
    <!--取消政策-->
    <swiper-slide v-if="programLabels.includes('policy')">
      <div class="h3">{{$t('CancellationPolicy')}}</div>
      <div class="rich-text" v-html="policy.current.content"></div>
      <cancellation-policy-table :policy="policy.policies"/>
    </swiper-slide>
    <!--滚动条-->
    <div class="swiper-scrollbar" slot="scrollbar"></div>
  </swiper>
</template>
<script>
  import CancellationPolicyTable from '../../../components/CancellationPolicyTable';
  import textarea from '../../../mixins/textarea';

  export default {
    name: 'Precautions',
    mixins: [textarea],
    props: {datas: Object},
    components: {CancellationPolicyTable},
    computed: {
      cost() {
        return (this.datas || {}).cost;
      },
      booking() {
        return (this.datas || {}).booking || {};
      },
      responsibility() {
        return (this.datas || {}).responsibility || {};
      },
      policy() {
        return (this.datas || {}).policy || {};
      },
      programLabels() {
        return ((this.datas || {}).programs || []).map(item => item.programLabel);
      }
    },
    data() {
      return {
        swiperOption: {
          direction: 'vertical',
          slidesPerView: 'auto',
          freeMode: true,
          scrollbar: {
            el: '.swiper-scrollbar'
          },
          mousewheel: true
        }
      };
    }
  };
</script>
<style scoped lang="less">
  .precautions{
    height:300px;margin:-20px;
    .h3{padding-bottom:10px;text-align:center;font-size:18px;font-weight:bold;}
    .m-t{margin-top:8px;}
    /deep/ .swiper-slide{height:auto;padding:20px;}
    /deep/ .table-policy{
      th,td{border-color:#ddd;}
    }
  }
</style>
