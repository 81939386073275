<template>
  <div class="payment">
    <div class="product-info">
      <div class="title">{{orderInfo.productTitle}}</div>
      <div class="order-info cl">
        <div class="fl">
          <span>{{$t('DepartureDate')}}：{{orderInfo.priceDate}}</span>
          <span>&#x3000;{{$t('Tour group number')}}：{{orderInfo.productNo}}</span>
        </div>
        <div class="fr price">
          <span class="unit">{{$t('Total amount')}}：{{orderInfo.billingType}}</span> {{orderInfo.totalPrice}}
        </div>
      </div>
    </div>
    <div class="countdown">
      <!--請於15分鐘內完成網上支付（倒數： ）-->
      <i18n path="PaymentCountdown">
        <countdown @finish="orderInvalid" ref="countdown"/>
      </i18n>
    </div>
    <tabs type="border-card" v-model="paymentType">
      <tab-pane :label="$t('AlipayHK')" name="alipayHK" :disabled="this.selectedPaymentType != '' && this.selectedPaymentType != 'alipayHK' ">
        <el-button type="primary" :loading="alipayHKLoading" @click="createAlipayHKOrder" >{{this.alipayHKMessage}}</el-button>

      </tab-pane>
      <tab-pane :label="$t('WechatpayHK')" name="wechatpayHK" :disabled="this.selectedPaymentType != '' && this.selectedPaymentType != 'wechatpayHK' ">
        <el-button type="primary" :loading="wechatpayHKLoading" @click="createWechatpayOrder" >{{this.wechatpayHKMessage}}</el-button>
      </tab-pane>
      <tab-pane label="PayPal" name="paypal" :disabled="this.selectedPaymentType != '' && this.selectedPaymentType != 'paypal' ">
        <div class="txt-c" v-loading="payPalOrderId==='LOADING'" element-loading-background="transparent">
          <div id="paypal-button" style="display:inline-block;"
                :style="{marginTop:payPalOrderId==='LOADING'?'80px':'20px'}">
          </div>
        </div>
      </tab-pane>
    </tabs>
<!--    <iframe v-if="isPaymentiframeShow" :src="paymentURL" allowpaymentrequest="true" width="100%" height="700px"></iframe>-->
  </div>
</template>
<script>
  import {Tabs, TabPane} from 'element-ui';
  import Countdown from '../../../components/Countdown';
  import {initPaypal} from '../../../utils/thirdParty';
  import {orderPay, getOrderPayStatus, orderContinuePay, verifyPaypal, getFirstPaymentTime} from '../../../api/order';

  export default {
    name: 'ConfirmPayment',
    props: {
      orderId: String,
      orderInfo: Object
    },
    data() {
      return {
        firstPaymentTime: null,
        isPaymentiframeShow: false,
        paymentURL: null,

        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        },
        timer: null,
        selectedPaymentType: '', // 已選擇的付款方式
        paymentType: 'alipayHK', // 選中的付款tab
        alipayHKLoading: false,
        alipayHKMessage: '支付寶（香港）',
        wechatpayHKLoading: false,
        wechatpayHKMessage: '微信支付（香港）',
        validityPeriod: '',
        aliPC2Mobile: '',
        payPalOrderId: ''
      };
    },
    components: {Countdown, Tabs, TabPane},
    // beforeRouteLeave(to, from, next) {
    //   const answer = window.confirm('当前页面数据未保存，确定要离开？');
    //   if (answer) {
    //     next();
    //   } else {
    //     next(false);
    //   }
    // },
    created() {
      this.$nextTick(() => {
        this.getFirstPaymentTime();
      });
    },
    destroyed() {
      this.clearTimer();
    },
    methods: {
      createAlipayHKOrder() {
        this.newWindow = window.open('', '_parent');
        this.$msgbox({
          title: '確認支付方式',
          message: '确定使用支付寶（香港）支付這張訂單嗎',
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '創建訂單...';
              this.selectedPaymentType = 'alipayHK';
              this.alipayHKLoading = true;
              this.alipayHKMessage = '正在創建支付寶香港訂單...';
              this.createOrder('alipayHK').then(response => {
                this.alipayHKMessage = '訂單創建成功，請在跳轉付款窗口掃QRCode付款';
                instance.confirmButtonLoading = false;

                // Replace href to payment gateway
                window.location.href = response.pay_apptrade;
                // window.open(response.pay_apptrade);

                done();
              }).catch(error => {
                this.alipayHKMessage = error;
              });
            } else {
              done();
            }
          }
        });
      },
      createWechatpayOrder() {
        this.$msgbox({
          title: '確認支付方式',
          message: '确定使用微信支付（香港）支付這張訂單嗎',
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '創建訂單...';
              this.selectedPaymentType = 'wechatpayHK';
              this.wechatpayHKLoading = true;
              this.wechatpayHKMessage = '正在創建微信香港訂單...';
              this.createOrder('wechatpayHK').then(response => {
                this.wechatpayHKMessage = '訂單創建成功，請在跳轉付款窗口掃QRCode付款';
                instance.confirmButtonLoading = false;

                // Replace href to payment gateway
                window.location.href = response.pay_apptrade;
                // window.open(response.pay_apptrade);

                done();
              }).catch(error => {
                this.wechatpayHKMessage = error;
              });
            } else {
              done();
            }
          }
        });
      },
      getFirstPaymentTime() {
        // 获取倒计时时剩余时间（毫秒）
        getFirstPaymentTime(this.orderId).then(res => {
          const value = res.value || {};
          const firstPaymentTime = value.firstPaymentTime;
          this.firstPaymentTime = value.firstPaymentTime;
          const systemTime = value.systemTime;
          const duration = 15 * 60 * 1000;
          const millisecond = firstPaymentTime ? (firstPaymentTime + duration - systemTime) : duration;
          if (millisecond <= 0) {
            this.orderInvalid();
          } else {
            this.$refs.countdown.start(millisecond);
          }
        });
      },
      // 創建付款平台訂單，並開始每秒詢問付款狀態
      createOrder(payPlatform) {
        const data = {orderId: this.orderId, payPlatform};
        const pay = this.$route.query.type === 'CONTINUE' ? orderContinuePay : orderPay;
        return new Promise((resolve, reject) => {
          pay(data).then(res => {
            resolve(res.value);
            // 開始每秒詢問一次付款是否成功
            if (!this.timer) {
              this.timer = setInterval(this.getOrderPayStatus, 900);
            }
          }).catch(e => {
            reject(e);
          });
        });
      },
      // 支付弹窗
      payMsgbox() {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.paid'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          confirmButtonText: this.$t('Paid'),
          cancelButtonText: this.$t('Unpaid')
        }).then(action => {
          // this.getOrderPayStatus();
        }).catch(() => {
          // this.getOrderPayStatus();
        });
      },
      // 查询订单支付状态
      getOrderPayStatus() {
        getOrderPayStatus(this.orderId).then(res => {
          // console.log((this.firstPaymentTime + (15 * 60 * 1000)) - new Date());
          this.$refs.countdown.updateEndTime((this.firstPaymentTime + (15 * 60 * 1000)) - new Date());
          if (res.value === 'success') {
            this.clearTimer();
            this.paymentSuccessful();
          }
        });
      },
      // 超时未支付弹窗
      orderInvalid() {
        this.clearTimer();
        // this.isPaymentiframeShow = false;
        // this.paymentURL = null;
        this.$alert(this.$t('Payment timeout'), this.$t('Prompt'), {
          confirmButtonText: '返回產品',
          showClose: false,
          callback: action => {
            this.$router.replace({name: 'TourismDetail', params: {id: this.orderInfo.productId}});
          }
        });
      },
      // 支付成功
      paymentSuccessful() {
        this.$alert(this.$t('Payment successful'), this.$t('Prompt'), {
          customClass: 'successful',
          type: 'success',
          showClose: false,
          callback: action => {
            this.$router.replace({name: 'OrderDetail', params: {id: this.orderId}});
          }
        });
      },
      // 清除查询订单定时器
      clearTimer() {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      },
      // paypal支付
      onPaypal() {
        if (this.payPalOrderId) {
          return false;
        }
        this.payPalOrderId = 'LOADING';
        this.createOrder('paypal').then(data => {
          this.payPalOrderId = data.payPalOrderId;
          initPaypal().then(() => {
            paypal.Button.render({
              env: 'production',
              locale: 'zh_HK', // zh_CN、zh_HK、en_US
              style: {
                size: 'large'
              },
              payment: (data, actions) => {
                return this.payPalOrderId;
              },
              onAuthorize: (data, actions) => {
                return verifyPaypal(this.payPalOrderId, {transactionTimeDuration: this.$refs.countdown.endTime}).then(res => {
                  if (res.value === 'success') {
                    this.getOrderPayStatus();
                  } else {
                    console.log('The payment has expired.');
                  }
                });
              }
            }, '#paypal-button');
          });
        }).catch(e => {
          this.payPalOrderId = '';
        });
      }
    },
    watch: {
      paymentType: function (value) {
        switch (value) {
          case 'aliPC2Mobile':
            if (!this.aliPC2Mobile) {
              this.orderPay('aliPC2Mobile', (data) => {
                this.aliPC2Mobile = data;
              });
            }
            break;
          case 'paypal':
            this.onPaypal();
            break;
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .payment{
    padding:30px;background-color:#fff;
    .actions{margin-top:20px;text-align:center;}
    /deep/ .el-tabs--border-card > .el-tabs__content{padding:30px 20px;}
  }
  .product-info{
    padding:20px;background-color:#f4f4f4;
    .title{font-size:18px;}
  }
  .order-info{
    margin-top:10px;
    .price{color:#ff6f61;font-size:24px;}
    .unit{font-size:16px;}
  }
  .countdown{margin:30px 0;color:#ff6f61;}
  .form-item{
    margin-bottom:20px;
    &:last-child{margin-bottom:0;}
    .label{min-width:84px;line-height:32px;}
    .ipt{
      width:300px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .select{width:300px;vertical-align:top;}
    .unit{padding:0 5px;line-height:32px;}
  }
  .qrcode{position:relative;width:200px;height:210px;margin:50px auto;}
</style>
