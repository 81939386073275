import request from '@/utils/request';

// 保存/修改旅客信息
export function saveTravelerInfo(data) {
  return request({
    url: '/traveler/save',
    method: 'post',
    data
  });
}

// 获取旅客列表
export function getTravelerList(data) {
  return request({
    url: '/traveler/list',
    method: 'get',
    params: data
  });
}

// 获取旅客详情
export function getTravelerDetail(travelerId) {
  return request({
    url: `/traveler/details/${travelerId}`,
    method: 'get'
  });
}

// 删除旅客
export function deleteTraveler(travelerId) {
  return request({
    url: `/traveler/delete/${travelerId}`,
    method: 'post'
  });
}

// 获取所有旅客列表（不分页）
export function getAllTraveler(data) {
  return request({
    url: '/traveler/list/not/page',
    method: 'get',
    params: data
  });
}
