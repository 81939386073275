<template>
  <div class="cl">
    <slot></slot>
    <div class="main-lf">
      <!--产品资料-->
      <div class="fl-group">
        <div class="hd">{{$t('Product information')}}</div>
        <div class="bd">
          <!--產品編號-->
          <p class="p2">{{$t('ProductNumber')}}：{{orderInfo.productNo}}</p>
          <!--產品名稱-->
          <p class="p2">{{$t('ProductName')}}：{{orderInfo.productTitle}}</p>
          <!--行程路線-->
          <p class="p2">{{$t('Route')}}：{{orderInfo.lineName}} - {{orderInfo.lineTitle}}</p>
          <!--旅行團出發日-->
          <p class="p2">{{$t('TourDepartureDay')}}：{{orderInfo.priceDate}}</p>
          <!--人數-->
          <p class="p2">{{$t('Number of people')}}：{{orderInfo.orderPeopleNumber}}</p>
          <!--房間數目-->
          <p class="p2" v-if="isDay===0">{{$t('NumberOfRooms')}}：{{orderInfo.roomsNumber}}</p>
          <template v-if="flightList&&flightList.length">
            <div class="h3">{{$t('FlightInformation')}}</div>
            <div class="flight-list" v-for="item of flightList" :key="item.flightId">
              <template v-if="item.isCustomize==='N'">
                <div class="item" v-for="fItem of item.flightDetails" :key="fItem.outsetDate">
                  <span>{{fItem.departure}}</span>
                  <span class="svg-icon-wrap">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="18" height="18"
                           viewBox="0 0 24 24">
                        <path d="M22 12l-4-4v3H3v2h15v3z"></path>
                      </svg>
                    </span>
                  <span class="spacing">{{fItem.destination}}</span>
                  <span class="spacing">{{fItem.departureDate|flightDate(fItem.fewDay)}}</span>
                  <span class="spacing">{{fItem.airline}} {{fItem.airlineNumber}}</span>
                  <span>{{fItem.outsetTime}}</span>
                  <span class="svg-icon-wrap">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="18" height="18"
                           viewBox="0 0 24 24">
                        <path d="M22 12l-4-4v3H3v2h15v3z"></path>
                      </svg>
                    </span>
                  <span>{{fItem.arrivalsTime}}</span>
                </div>
              </template>
              <div class="rich-text" v-html="item.customizeContent" v-else-if="item.isCustomize==='Y'"></div>
            </div>
          </template>
        </div>
      </div>
      <!--旅客資料-->
      <div class="fl-group" v-if="isTicket === 'N'">
        <div class="hd">{{$t('Traveler information')}}</div>
        <div class="bd tablefontSize">
          <table class="table">
            <td>
              <tr class="b">{{$t('Passenger Name')}}({{$t('Last Name')}}/{{$t('Ming')}})</tr>
              <tr class="b">{{$t('Appellation')}}</tr>
              <tr class="b">{{$t('Category')}}</tr>
              <tr class="b">{{$t('Certificate Type')}}</tr>
              <tr class="b">{{$t('Certificate Number')}}</tr>
              <tr class="b" v-if="orderInfo.roomsNumber%1 === 0.5 && isDay===0">{{$t('FightPassenger')}}</tr>
            </td>
            <td v-for="(item,index) of passengerList" :key="index">
              <tr>{{item.englishSurname}}/{{item.englishName}}</tr>
              <tr>{{item.isCalled|dictName(appellationOptions)}}</tr>
              <tr>{{(item.travelerType+item.isOccupyBed)|dictName('travelerType')}}</tr>
              <tr>{{item.paperworkType|dictName(certificateTypeOptions)}}</tr>
              <tr>{{item.idNumber}}</tr>
              <tr v-if="orderInfo.roomsNumber%1 === 0.5 && isDay===0">
                {{item.isFight==='Y'?$t('SharedRoom'):''}}
              </tr>
            </td>
          </table>
        </div>
      </div>
      <!--聯絡人資料-->
      <div class="fl-group">
        <div class="hd">{{$t('ContactInformation')}}</div>
        <div class="bd tablefontSize">
          <table class="table">
            <td>
              <tr class="b">{{$t('Contact Name')}}({{$t('English')}})</tr>
              <tr class="b">{{$t('Contact Name')}}({{$t('Chinese')}})</tr>
              <tr class="b">{{$t('Appellation')}}</tr>
              <tr class="b">{{$t('Contact Number')}}</tr>
              <tr class="b">{{$t('Email')}}</tr>
            </td>
            <td>
              <tr>{{contactInformation.englishSurname}}/{{contactInformation.englishName}}</tr>
              <tr>{{contactInformation.chineseSurname}}/{{contactInformation.chineseName}}</tr>
              <tr>{{contactInformation.isCalled|dictName(appellationOptions)}}</tr>
              <tr>({{contactInformation.phoneType}}) {{contactInformation.userPhone}}</tr>
              <tr>{{contactInformation.userEmail}}</tr>
            </td>
          </table>
        </div>
      </div>
      <!--紧急联系人-->
      <div class="fl-group" v-if="emergencyContact.isUrgent">
        <div class="hd">{{$t('Emergency Contact')[0]}}</div>
        <div class="bd">
          <table class="table">
            <tr>
              <td class="b">{{$t('Contact Name')}}({{$t('English')}})</td>
              <td class="b">{{$t('Contact Name')}}({{$t('Chinese')}})</td>
              <td class="b">{{$t('Contact Number')}}</td>
            </tr>
            <tr>
              <td>{{emergencyContact.englishSurname}}/{{emergencyContact.englishName}}</td>
              <td>{{emergencyContact.chineseSurname}}{{emergencyContact.chineseName}}</td>
              <td>({{emergencyContact.phoneType}}) {{emergencyContact.userPhone}}</td>
            </tr>
          </table>
        </div>
      </div>
      <!--收據-->
      <div class="fl-group" v-if="gainGround">
        <div class="hd">{{$t('Receipt')}}</div>
        <div class="bd">
          <!--收據抬頭-->
          <div class="form-item">
            <div class="label">{{$t('Receipt up')}}：{{gainGround}}</div>
          </div>
        </div>
      </div>
      <!--其他需求-->
      <div class="fl-group" v-if="otherDemands">
        <div class="hd">{{$t('More demand')}}</div>
        <div class="bd">
          <!--其他需求-->
          <div class="form-item">
            <div class="label">{{$t('Other demands')}}：</div>
            <div v-html="formatTextareaContent(otherDemands)"></div>
          </div>
        </div>
      </div>
      <!--詳細收費-->
      <div class="fl-group" v-if="isTicket === 'Y'">
        <div class="hd">{{$t('Detailed Charge')}}</div>
        <div class="bd">
          <table class="table">
            <tr>
              <td class="b">{{$t('Project')}}</td>
              <td class="b">{{$t('Price')}}</td>
              <td class="b">{{$t('Quantity')}}</td>
              <td class="b">{{$t('Amount')}}</td>
            </tr>
            <tr>
              <td>{{$t(orderInfo.priceType!=='1'?'Adult':'Passenger')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.adultPrice/100}}</td>
              <td>{{orderInfo.adultNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.adultPrice*orderInfo.adultNumber/100}}</td>
            </tr>
            <tr v-if="orderInfo.elderlyNumber">
              <td>{{$t('Elderly')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.elderlyPrice/100}}</td>
              <td>{{orderInfo.elderlyNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.elderlyNumber*orderInfo.elderlyPrice/100}}</td>
            </tr>
            <tr v-if="orderInfo.childNumber">
              <td>{{$t('Child')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.childPrice/100}}</td>
              <td>{{orderInfo.childNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.childNumber*orderInfo.childPrice/100}}</td>
            </tr>
            <tr v-if="orderInfo.notChildNumber">
              <td>{{$t('Child')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.notChildPrice/100}}</td>
              <td>{{orderInfo.notChildNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.notChildNumber*orderInfo.notChildPrice/100}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="fl-group" v-else>
        <div class="hd">{{$t('Detailed Charge')}}</div>
        <div class="bd">
          <table class="table">
            <tr>
              <td class="b">{{$t('Project')}}</td>
              <td class="b">{{$t('Price')}}</td>
              <td class="b">{{$t('Quantity')}}</td>
              <td class="b">{{$t('Amount')}}</td>
            </tr>
            <tr>
              <td>{{$t(orderInfo.priceType!=='1'?'AdultPrice':'Passenger')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.adultPrice/100}}</td>
              <td>{{orderInfo.adultNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.adultPrice*orderInfo.adultNumber/100}}</td>
            </tr>
            <tr v-if="orderInfo.childNumber">
              <td>{{$t('ChildPrice')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.childPrice/100}}</td>
              <td>{{orderInfo.childNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.childNumber*orderInfo.childPrice/100}}</td>
            </tr>
            <tr v-if="orderInfo.notChildNumber">
              <td>{{$t('NotChildPrice')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.notChildPrice/100}}</td>
              <td>{{orderInfo.notChildNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.notChildNumber*orderInfo.notChildPrice/100}}</td>
            </tr>
            <tr v-if="orderInfo.babyNumber">
              <td>{{$t('BabyPrice')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.babyPrice/100}}</td>
              <td>{{orderInfo.babyNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.babyNumber*orderInfo.babyPrice/100}}</td>
            </tr>
            <tr v-if="orderInfo.singleBedNumber">
              <td>{{$t('SingleBedDifference')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.singleBedPrice/100}}</td>
              <td>{{orderInfo.singleBedNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.singleBedNumber*orderInfo.singleBedPrice/100}}</td>
            </tr>
            <template v-if="orderInfo.isInsurance==='Y'">
              <tr>
                <td>{{$t('Insurance')}}{{orderInfo.priceType!=='1'?`-${$t('Adult')}`:''}}</td>
                <td>{{orderInfo.billingType}} {{orderInfo.insuranceAdultPrice/100}}</td>
                <td>{{orderInfo.adultNumber}}</td>
                <td>{{orderInfo.billingType}} {{orderInfo.insuranceAdultTotalPrice}}</td>
              </tr>
              <tr
                v-if="orderInfo.priceType!=='1'&&(orderInfo.childNumber+orderInfo.notChildNumber+orderInfo.babyNumber)">
                <td>{{$t('Insurance')}}-{{$t('Child')}}/{{$t('Baby')}}</td>
                <td>{{orderInfo.billingType}} {{orderInfo.insuranceChildPrice/100}}</td>
                <td>{{orderInfo.childNumber+orderInfo.notChildNumber+orderInfo.babyNumber}}</td>
                <td>{{orderInfo.billingType}} {{orderInfo.insuranceChildTotalPrice}}</td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <!--注意事項-->
      <div class="fl-group">
        <div class="hd">{{$t('Precautions')}}</div>
        <div class="bd">
          <precautions :datas="productInfo"/>
        </div>
      </div>
      <!--付款方式-->
      <div class="fl-group">
        <div class="hd">{{$t('Can use payment method')}}</div>
        <div class="bd payments paymentsInCheckOrder">
          <div class="item">
            <div class="title">Paypal</div>
            <div>
              <img height="24" src="../../../assets/images/icon/paypal.png" alt=""/>
            </div>
          </div>
          <div class="item">
            <div class="title">{{$t('UnionPay')}}</div>
            <div>
              <img height="28" src="../../../assets/images/icon/unionpay.png" alt=""/>
            </div>
          </div>
          <div class="item">
            <div class="title">{{$t('Alipay')}}</div>
            <div>
              <img src="../../../assets/images/icon/alipay.png" alt=""/>
            </div>
          </div>
          <div class="item">
            <div class="title">{{$t('WechatpayHK')}}</div>
            <div>
              <img src="../../../assets/images/icon/wechat.png" alt="" width="40"/>
            </div>
          </div>
        </div>
      </div>
      <div>
        <checkbox class="cbox-wrap" v-model="agreePrecautions">
          <div class="checkbox-label">
            {{$t('I have checked the contents of the above order, and have read and agreed to the tour group registration, liability details and relevant precautions.')}}
          </div>
        </checkbox>
        <checkbox class="cbox-wrap" v-model="agreeVisa">
          <i18n path="Agree to bring your own valid visa" tag="div" class="checkbox-label">
            <a @click.stop="()=>$refs.visa.show()"> {{$t('Visa considerations')}} </a>
          </i18n>
        </checkbox>
      </div>
      <div class="actions txt-c">
        <button class="button" @click="onPrev">{{$t('PreviousStep')}}</button>
        <button class="button button-primary" @click="saveOrderMaterial">
          {{$t('Payment')}}
        </button>
      </div>
    </div>
    <visa :datas="productInfo" ref="visa"/>
  </div>
</template>
<script>
  import textarea from '@/mixins/textarea';
  import Checkbox from '../../../components/Checkbox';
  import Precautions from './Precautions';
  import Visa from './Visa';
  import {getProductFlight} from '../../../api/product';
  import {saveOrderMaterial} from '../../../api/order';

  export default {
    name: 'ConfirmCheckOrder',
    props: {
      orderId: String,
      orderInfo: Object,
      passengerList: Array,
      contactInformation: Object,
      emergencyContact: Object,
      appellationOptions: Array,
      certificateTypeOptions: Array,
      gainGround: String,
      otherDemands: String,
      productInfo: Object,
      isDay: Number,
      isTicket: String
    },
    mixins: [textarea],
    data() {
      return {
        flightList: [],
        agreePrecautions: false,
        agreeVisa: false
      };
    },
    components: {Visa, Precautions, Checkbox},
    created() {
      // 跳到頭
      window.scrollTo(0, 0);
      // this.getProductFlight();
    },
    methods: {
      // 根据路线及日期获取航班资料
      getProductFlight() {
        getProductFlight(this.orderInfo.lineId, this.orderInfo.priceDate).then(res => {
          this.flightList = res.value;
        });
      },
      // 上一步
      onPrev() {
        this.$emit('previous');
      },
      // 下一步
      onNext() {
        this.$emit('next');
      },
      // 保存订单资料
      saveOrderMaterial() {
        if (!this.agreePrecautions || !this.agreeVisa) {
          this.$alert(this.$t('Please accept all the terms and reminder first!'), this.$t('Prompt'), {
          });
          return;
        }

        let datas = {
          travelers: this.passengerList.map((item, index) => {
            item.sort = index + 1;
            return item;
          }),
          contacts: [this.contactInformation],
          memo: {gainGround: this.gainGround, otherDemands: this.otherDemands}
        };

        if (this.emergencyContact.isUrgent) {
          datas.contacts.push(this.emergencyContact);
        }

        const loading = this.$loading({text: 'Loading'});
        saveOrderMaterial(this.orderId, datas).then(res => {
          if (res.value.redirect === 'orderDetail') {
            window.location.href = 'https://unutravel.com/user/order/detail/' + this.orderId;
          }
          loading.close();
          this.onNext();
        }).catch(() => {
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">

  .main-lf{
    padding:0 30px 30px;overflow:hidden;background-color:#fff;
    .cbox-wrap{
      margin-top:10px;
      &:first-child{margin-top:20px;display: flex;}
      a{text-decoration:underline;color:#ff6f61;}
    }
  }
  .fl-group{
    .tablefontSize{
      font-size: 16px;
      line-height: 28px;
    }
    .paymentsInCheckOrder{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    }
    .hd{
      position:relative;margin:30px 0 20px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .bd{padding:20px;background-color:#f4f4f4;border-radius:4px;}
    .row{
      margin-top:10px;
      &:first-child{margin-top:0;}
      > .fl{width:50%;}
    }
    .h3{margin-top:30px;color:#000;font-size:18px;}
    .p2{
      line-height:32px;color:#321908;font-size:16px;
      .b{font-weight:bold;}
    }
  }
  .flight-list{
    .item{
      margin-top:10px;line-height:1;
      > span{display:inline-block;vertical-align:middle;}
    }
    .spacing{margin-right:20px;}
    .svg-icon-wrap{margin:0 5px;}
  }
  .table{
    width:100%;
    td{padding:6px 8px;}
  }
  .payments{
    overflow:hidden;
    .item{float:left;margin:0 50px;}
    .title{margin-bottom:8px;}
  }
  .actions{
    margin-top:40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .button-primary{margin:10px;}
    .button{margin:10px;}
  }
</style>
