<template>
  <div class="main">
    <div class="layout" v-if="!loading">
      <steps :active="step"/>
      <div class="cl breakable" v-if="step===0">
        <div class="main-lf">
          <div class="fl-group" v-if="isTicket === 'N'">
            <div class="hd cl">
              <!--<a class="btn-add-passenger" href="javascript:;" @click="addPassenger">+ {{$t('Adding passengers')}}</a>-->
              {{$t('Traveler information')}} ({{$t('Fields marked * are required')}})
            </div>
            <!--旅客資料-->
            <passenger-info
                            :is-day="isDay"
                            :order="orderInfo"
                            :appellation-options="appellationOptions"
                            :area-code-options="areaCodeOptions"
                            :certificate-type-options="certificateTypeOptions"
                            :passenger-list.sync="passengerInfo"/>
          </div>
          <!--拼房旅客-->
          <div class="fl-group" v-if="orderInfo.roomsNumber%1===0.5&&orderInfo.adultNumber>1&&isDay===0">
            <div class="hd">{{$t('FightPassenger')}}</div>
            <div class="bd">
              <join-room :passenger-info.sync="passengerInfo"/>
            </div>
          </div>
          <!--联系人资料-->
          <div class="fl-group">
            <div id="contactInformation" class="hd">{{$t('ContactInformation')}} ({{$t('Fields marked * are required')}})</div>
            <div class="bd">
              <confirm-contact-information v-if="isTicket === 'N'"
                                           :area-code-options="areaCodeOptions"
                                           :appellation-options="appellationOptions"
                                           :datas.sync="contactInformation"
                                           :emergency-contact="emergencyContact"
                                           :passenger-list.sync="passengerInfo"/>
              <confirm-contact-information v-if="isTicket === 'Y'"
                :area-code-options="areaCodeOptions"
                                           :appellation-options="appellationOptions"
                                           :datas.sync="contactInformation"
                                           :emergency-contact="emergencyContact"
                                           :passenger-list.sync="travelerList"/>
            </div>
          </div>
          <!--收據-->
          <div class="fl-group">
            <div class="hd">{{$t('Receipt')}}</div>
            <div class="bd">
              <!--收據抬頭-->
              <div class="form-item">
                <div class="label">{{$t('Receipt up')}}({{$t('Optional')}})</div>
                <div>
                  <input class="ipt m-r" type="text" autocomplete="off"
                         :placeholder="$t('Electronic receipt [Payee] name (optional)')"
                         v-model.trim="gainGround"/>
                </div>
              </div>
            </div>
          </div>
          <!--其他需求-->
          <div class="fl-group">
            <div class="hd">{{$t('More demand')}}</div>
            <div class="bd">
              <!--其他需求-->
              <div class="form-item">
                <div class="label">{{$t('Other demands')}}({{$t('Optional')}})</div>
                <div>
                  <el-input type="textarea"
                            :placeholder="$t('PleaseEnter')"
                            show-word-limit
                            maxlength="1000"
                            v-model="otherDemands">
                  </el-input>
                  <p class="tips">{{$t('Tips')[0]}}</p>
                </div>
              </div>
            </div>
          </div>
          <!--使用条款-->
          <!--<div class="fl-group">
            <div class="hd">{{$t('termsOfUse')}}</div>
            <div class="bd">
              <p v-for="i in 8" :key="i">使用條款</p>
            </div>
          </div>-->
        </div>
        <confirm-right-sidebar class="rightsidebar " :scroll-top="scrollTop" is-fixed :order-info="orderInfo" :isDay="isDay" :is-ticket="isTicket" @submit="onNext"/>
      </div>
      <confirm-check-order :order-id="orderId"
                           :order-info="orderInfo"
                           :passenger-list.sync="passengerInfo"
                           :contact-information="contactInformation"
                           :emergency-contact="emergencyContact"
                           :appellation-options="appellationOptions"
                           :certificate-type-options="certificateTypeOptions"
                           :gain-ground="gainGround"
                           :other-demands="otherDemands"
                           :product-info="productInfo"
                           :isDay="isDay"
                           :is-ticket="isTicket"
                           @previous="setStep(0)"
                           @next="setStep(2)"
                           v-else-if="step===1">
       <confirm-right-sidebar :order-info="orderInfo" :show-btn-submit="false"/>
      </confirm-check-order>

      <payment :order-id="orderId" :order-info="orderInfo" v-else-if="step===2"/>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Vuelidate from 'vuelidate';
  import {required, requiredIf, email} from 'vuelidate/lib/validators';
  import Steps from '../../components/Steps';
  import Payment from './components/ConfirmPayment';
  import PassengerInfo from './components/PassengerInfo';
  import ConfirmRightSidebar from './components/ConfirmRightSidebar';
  import ConfirmContactInformation from './components/ConfirmContactInformation';
  import ConfirmCheckOrder from './components/ConfirmCheckOrder';
  import JoinRoom from './components/JoinRoom';
  import order from '../../mixins/order';
  import {scrollToY} from '../../utils';
  import {getDictList} from '../../api/common';
  import {getOrderMaterial} from '../../api/order';
  import {getProductLineDetail} from '../../api/product';
  import {getAllTraveler} from '@/api/traveler';

  Vue.use(Vuelidate);
  export default {
    name: 'ConfirmOrder',
    data() {
      return {
        loading: true,
        step: 0,
        orderId: '',
        orderInfo: {},
        areaCodeOptions: [],
        appellationOptions: [],
        certificateTypeOptions: [],
        passengerInfo: [],
        contactInformation: {},
        emergencyContact: {},
        gainGround: '',
        otherDemands: '',
        scrollTop: 0,
        productInfo: {},
        isDay: 0,
        isTicket: '',
        travelerList: []
      };
    },
    computed: {
      isOneDay() {
        if (this.isDay === 1) {
          return false;
        } else {
          return true;
        }
      },
      isTicketProduct() {
        if (this.isTicket === 'Y') {
          return false;
        } else {
          return true;
        }
      }
    },
    validations: {
      passengerInfo: {
        required: requiredIf(function() {
          return this.isTicketProduct;
        }),
        $each: {
          isCalled: {required: requiredIf(function() {
              return this.isTicketProduct;
            })},
          englishSurname: {required: requiredIf(function() {
              return this.isTicketProduct;
            })},
          englishName: {required: requiredIf(function() {
              return this.isTicketProduct;
            })},
          chineseSurname: {},
          chineseName: {},
          genderType: {required: requiredIf(function() {
              return this.isTicketProduct;
            })},
          paperworkType: {required: requiredIf(function() {
              return this.isTicketProduct;
            })},
          otherPaperwork: {
            // required: requiredIf((datas) => {
            //    if (this.isTicket === 'N') {
            //      return datas.paperworkType === 'qt';
            //    } else {
            //      return false;
            //    }
            // })
          },
          issuingCountry: {required: requiredIf(function() {
              return this.isTicketProduct;
            })},
          idNumber: {required: requiredIf(function() {
              return this.isTicketProduct;
            })},
          effectiveDate: {
            // required: requiredIf((datas) => {
            //   if (this.isTicket === 'N') {
            //     return datas.paperworkType !== 'sfz';
            //   } else {
            //     return false;
            //   }
            // })
          },
          birthday: {
            // required: requiredIf(function() {
            //   if (this.isTicket === 'N') {
            //     return this.isOneDay;
            //   } else {
            //     return false;
            //   }
            // })
          },
          phoneType: {},
          userPhone: {},
          travelerType: {}
        }
      },
      contactInformation: {
        isCalled: {required},
        englishSurname: {required},
        englishName: {required},
        phoneType: {required},
        userPhone: {required},
        userEmail: {required, email}
      },
      emergencyContact: {
        englishSurname: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        },
        englishName: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        },
        phoneType: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        },
        userPhone: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        }
      }
    },
    components: {
      JoinRoom, ConfirmCheckOrder, ConfirmContactInformation, ConfirmRightSidebar, PassengerInfo, Payment, Steps
    },
    mixins: [order],
    mounted() {
      if (this.$route.query.active === 'PAYMENT') {
        this.step = 2;
      }
    },
    created() {
      this.orderId = this.$route.query.orderId;
      this.getDicts();
      this.getOrderMaterial();
      this.getTravelerList();

      this.$nextTick(() => {
        document.querySelector('#app').addEventListener('scroll', this.onScroll);
      });
    },
    destroyed() {
      document.querySelector('#app').removeEventListener('scroll', this.onScroll);
    },
    methods: {
      // 获取旅客信息列表
      getTravelerList() {
        getAllTraveler().then(res => {
          this.travelerList = res.value || [];
        });
      },
      onScroll() {
        this.scrollTop = document.querySelector('#app').scrollTop;
      },
      // 获取字典
      getDicts() {
        // 手机区号
        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value || [];
        });

        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.appellationOptions = res.value || [];
        });

        // 证件类型
        getDictList('certificateType').then(res => {
          this.certificateTypeOptions = res.value || [];
        });
      },
      setStep(value) {
        this.step = value;
        this.$nextTick(() => {
          scrollToY();
        });
      },
      // 获取订单资料
      getOrderMaterial() {
        const loading = this.$loading({lock: true, text: 'Loading', background: 'rgba(0, 0, 0, 0.8)'});
        getOrderMaterial(this.orderId).then(res => {
          let order = res.value.order || {};
          const contacts = res.value.contacts || [];
          const memo = res.value.memo || {};
          console.log(res.value);
          this.isDay = res.value.product.isDay;
          this.isTicket = res.value.product.isTicket;
          this.orderInfo = this.formatOrder(order);
          this.passengerInfo = res.value.travelers || [];
          this.contactInformation = this.createContactInformation(contacts);
          this.emergencyContact = this.createContactInformation(contacts, 'urgent');
          this.gainGround = memo.gainGround || '';
          this.otherDemands = memo.otherDemands || '';
          this.createPassengers();
          this.getProductDetail();
          loading.close();
        }).catch(() => {
          loading.close();
        });
      },
      // 生成旅客信息表
      createPassengers() {
        const {adultNumber, childNumber, notChildNumber, babyNumber, roomsNumber} = this.orderInfo;
        if (!this.passengerInfo.length) {
          this.step = 0; // 如果未填写旅客信息，要先填写旅客信息
          this.passengerInfo = [
            ...this.createPassengerList(adultNumber, 'adult', 'Y'),
            ...this.createPassengerList(childNumber, 'child', 'Y'),
            ...this.createPassengerList(notChildNumber, 'child', 'N'),
            ...this.createPassengerList(babyNumber, 'baby', 'N')
          ];

          // 如果客戶訂購旅團時選擇房間數目有0.5时，第一位旅客默认拼房
          if (roomsNumber % 1 === 0.5) {
            this.passengerInfo[0].isFight = 'Y';
          }
        } else {
          this.step = 2;
        }
        this.loading = false;
      },
      // 下一步
      onNext() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$message.warning(this.$t('Please complete the information before submitting'));
          this.$nextTick(() => {
            if (document.querySelector('.is-error')) {
              scrollToY(undefined, document.querySelector('.is-error').offsetTop - 80);
            }
          });
          return false;
        }
        this.setStep(1);
      },
      // 获取产品详情
      getProductDetail() {
        const lineId = this.orderInfo.lineId;
        getProductLineDetail(lineId).then(res => {
          const value = res.value || {};
          this.productInfo = value;
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .breakable{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #fff;
  }
  .rightsidebar{
    width: 100%;
    margin: 0 25px 10px 25px;
    border-style:solid;
    border-width:1px;
    border-color: #f4f4f4;
  }
  .main{min-height:400px;margin-bottom:-30px;padding-bottom:30px;}
  .main-lf{width: 100%; padding:30px;overflow:hidden;background-color:#fff;}
  .fl-group{
    .hd{
      position:relative;margin:30px 0 20px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .bd{padding:20px;background-color:#f4f4f4;border-radius:4px;}
    .form-item{
      margin-bottom:24px;
      &:last-child{margin-bottom:0;}
      .label{margin-bottom:8px;}
      .ipt{
        width:100%;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
        &:focus{border-color:#ff6f61;}
      }
    }
    .tips{margin-top:4px;color:#666;font-size:12px;}
    /deep/ .el-textarea .el-textarea__inner{height:96px;padding:10px;}
  }
  .btn-add-passenger{float:right;padding:0 8px;line-height:28px;color:#1890ff;font-size:14px;border:1px solid #1890ff;border-radius:4px;}
</style>
