<template>
  <Modal :title="$t('VisaRequirements')" width="960px" :is-show="isShow" v-if="isShow" @close-modal="hide">
    <div class="visa-wrap">
      <tabs v-if="visas&&visas.length" :value="visas[0].id">
        <tab-pane v-for="item of visas" :label="item.location" :name="item.id" :key="item.id">
          <div class="rich-text" v-html="item.content"></div>
        </tab-pane>
      </tabs>
    </div>
  </Modal>
</template>
<script>
  import {Tabs, TabPane} from 'element-ui';

  export default {
    name: 'Visa',
    props: {datas: Object},
    components: {Tabs, TabPane},
    data() {
      return {
        isShow: false
      };
    },
    computed: {
      visas() {
        return (this.datas || {}).visas;
      }
    },
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      }
    }
  };
</script>
<style scoped lang="less">
  .visa-wrap{padding:20px;text-align:left;}
</style>
