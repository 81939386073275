<template>
  <div v-if="$parent.$v">
    <div class="label">
      <div class="tips">{{$t('Choose passengers above as the contact person')}}</div>
    </div>
    <div class="form-item">
      <div>
        <el-select class="select" filterable size="small" :placeholder="$t('PleaseChoose')"
                   v-model="traveler" @change="selectedContractPerson">
          <el-option v-for="item of passengerList"
                     :key="item.englishSurname"
                     :label="`${item.englishSurname} ${item.englishName} (${item.chineseSurname}${item.chineseName})`"
                     :value="item.englishSurname">
          </el-option>
        </el-select>
        <a class="btn-clear" @click="handleClear">{{$t('Clear form')}}</a>
      </div>
    </div>
    <!--稱謂-->
    <div class="form-item">
      <div class="label" :class="{'is-error': $parent.$v.contactInformation.isCalled.$error}">
        <span class="required">*</span>{{$t('Appellation')}}
      </div>
      <div>
        <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                   :class="{'is-error': $parent.$v.contactInformation.isCalled.$error}"
                   @blur="$parent.$v.contactInformation.isCalled.$touch()"
                   v-model="datas.isCalled">
          <el-option v-for="item in appellationOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
    </div>
    <!--姓名(英文)-->
    <div class="form-item">
      <div class="label"
           :class="{'is-error': $parent.$v.contactInformation.englishSurname.$error || $parent.$v.contactInformation.englishName.$error}">
        <span class="required">*</span>{{$t('Name')}}({{$t('English')}})
      </div>
      <div>
        <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓 Surname"
               :class="{'is-error': $parent.$v.contactInformation.englishSurname.$error}"
               @blur="$parent.$v.contactInformation.englishSurname.$touch()"
               v-model.trim="datas.englishSurname"/>
        <input class="ipt" type="text" autocomplete="off" placeholder="名 Given Name"
               :class="{'is-error': $parent.$v.contactInformation.englishName.$error}"
               @blur="$parent.$v.contactInformation.englishName.$touch()"
               v-model.trim="datas.englishName"/>
      </div>
    </div>
    <!--姓名(中文)-->
    <div class="form-item">
      <div class="label">{{$t('Name')}}({{$t('Chinese')}})</div>
      <div>
        <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓"
               v-model.trim="datas.chineseSurname"/>
        <input class="ipt" type="text" autocomplete="off" placeholder="名"
               v-model.trim="datas.chineseName"/>
      </div>
    </div>
    <!--手提電話-->
    <div class="form-item">
      <div class="label"
           :class="{'is-error': $parent.$v.contactInformation.phoneType.$error || $parent.$v.contactInformation.userPhone.$error}">
        <span class="required">*</span>{{$t('Mobile Number')}}
      </div>
      <div class="cl">
        <el-select class="select m-r" size="small" :placeholder="$t('Area code')"
                   :class="{'is-error': $parent.$v.contactInformation.phoneType.$error}"
                   @blur="$parent.$v.contactInformation.phoneType.$touch()"
                   v-model="datas.phoneType">
          <el-option v-for="item in areaCodeOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
        <input class="ipt" type="number" autocomplete="off" :placeholder="$t('Mobile Number')"
               :class="{'is-error': $parent.$v.contactInformation.userPhone.$error}"
               @blur="$parent.$v.contactInformation.userPhone.$touch()"
               v-model.trim="datas.userPhone"/>
      </div>
    </div>
    <!--Email-->
    <div class="form-item">
      <div class="label" :class="{'is-error': $parent.$v.contactInformation.userEmail.$error}">
        <span class="required">*</span>{{$t('Email')}}
      </div>
      <div>
        <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
               :class="{'is-error': $parent.$v.contactInformation.userEmail.$error}"
               @blur="$parent.$v.contactInformation.userEmail.$touch()"
               v-model.trim="datas.userEmail"/>
      </div>
    </div>
    <!--緊急聯絡人（建議非出行人士）-->
    <div id="emergencyContact" class="form-item emergency-contact">
      <div class="label">
        <checkbox v-model="emergencyContact.isUrgent">
          <div class="checkbox-label b">{{$t('Emergency Contact')[1]}}</div>
        </checkbox>
      </div>
    </div>
    <template v-if="emergencyContact.isUrgent">
      <!--姓名(英文)-->
      <div class="form-item">
        <div class="label" :class="{'is-error': $parent.$v.emergencyContact.englishSurname.$error || $parent.$v.emergencyContact.englishName.$error}">
          <span class="required">*</span>{{$t('Name')}}({{$t('English')}})
        </div>
        <div>
          <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓 Surname"
                 :class="{'is-error': $parent.$v.emergencyContact.englishSurname.$error}"
                 @blur="$parent.$v.emergencyContact.englishSurname.$touch()"
                 v-model.trim="emergencyContact.englishSurname"/>
          <input class="ipt" type="text" autocomplete="off" placeholder="名 Given Name"
                 :class="{'is-error': $parent.$v.emergencyContact.englishName.$error}"
                 @blur="$parent.$v.emergencyContact.englishName.$touch()"
                 v-model.trim="emergencyContact.englishName"/>
        </div>
      </div>
      <!--姓名(中文)-->
      <div class="form-item">
        <div class="label">{{$t('Name')}}({{$t('Chinese')}})</div>
        <div>
          <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓"
                 v-model.trim="emergencyContact.chineseSurname"/>
          <input class="ipt" type="text" autocomplete="off" placeholder="名"
                 v-model.trim="emergencyContact.chineseName"/>
        </div>
      </div>
      <!--手提電話-->
      <div class="form-item">
        <div class="label" :class="{'is-error': $parent.$v.emergencyContact.phoneType.$error || $parent.$v.emergencyContact.userPhone.$error}">
          <span class="required">*</span>{{$t('Mobile Number')}}
        </div>
        <div class="cl">
          <el-select class="select m-r" size="small" :placeholder="$t('Area code')"
                     :class="{'is-error': $parent.$v.emergencyContact.phoneType.$error}"
                     @blur="$parent.$v.emergencyContact.phoneType.$touch()"
                     v-model="emergencyContact.phoneType">
            <el-option v-for="item in areaCodeOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
          <input class="ipt" type="number" autocomplete="off" :placeholder="$t('Mobile Number')"
                 :class="{'is-error': $parent.$v.emergencyContact.userPhone.$error}"
                 @blur="$parent.$v.emergencyContact.userPhone.$touch()"
                 v-model.trim="emergencyContact.userPhone"/>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import Checkbox from '../../../components/Checkbox';

  export default {
    name: 'ConfirmContactInformation',
    components: {Checkbox},
    props: {
      areaCodeOptions: Array,
      appellationOptions: Array,
      datas: Object,
      emergencyContact: Object,
      passengerList: Array
    },
    data() {
      return {
        traveler: ''
      };
    },
    methods: {
      handleClear() {
        let datas = {...this.datas};
        for (let key in datas) {
          datas[key] = '';
        }
        this.datas = datas;
        this.traveler = '';
      },
      selectedContractPerson(value) {
        const datas = this.passengerList.find(item => item.englishSurname === value) || {};

        this.datas.isCalled = datas.isCalled;
        this.datas.englishSurname = datas.englishSurname;
        this.datas.englishName = datas.englishName;
        this.datas.chineseSurname = datas.chineseSurname;
        this.datas.chineseName = datas.chineseName;
        this.datas.phoneType = datas.phoneType;
        this.datas.userPhone = datas.userPhone;
      }
    }
  };
</script>
<style scoped lang="less">
  .form-item{
    margin-bottom:24px;
    &:last-child{margin-bottom:0;}
    .label{
      margin-bottom:8px;
      .tips{margin-top:4px;color:#999;}
    }
    .ipt{
      width:170px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .select{width:170px;}
    .m-r{margin-right:8px;}
    .required{color:#f00;}
  }
  .emergency-contact{padding-top:24px;border-top:1px solid #ccc;}
</style>
