<template>
  <div class="steps">
    <div class="item" :class="{'is-process':active===0,'is-finish':active>0}">
      <div class="line"></div>
      <div class="title">{{$t('Fill in the information')}}</div>
    </div>
    <div class="item" :class="{'is-process':active===1,'is-finish':active>1}">
      <div class="line"></div>
      <div class="title">{{$t('Determining an order')}}</div>
    </div>
    <div class="item" :class="{'is-process':active===2,'is-finish':active>2}">
      <div class="line"></div>
      <div class="title">{{$t('Payment')}}</div>
    </div>
    <div class="item" :class="{'is-process':active===3,'is-finish':active>3}">
      <div class="line"></div>
      <div class="title">{{$t('View Order')}}</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Steps',
    props: {
      active: {
        type: Number,
        default: 0
      }
    }
  };
</script>
<style scoped lang="less">
  .steps{
    margin-bottom:20px;padding:40px 0 20px;text-align:center;font-size:0;border-bottom:1px solid #e1e1e1;
    display: flex;
    justify-content: center;
    background-color: #f4f4f4;
    .item{
      display:inline-block;width:180px;opacity:0.5;
      &:last-child{
        .line{background-color:#f4f4f4;}
      }
      &.is-finish{opacity:1;}
      &.is-process{
        opacity:1;
        .line{
          background-color:rgba(254, 111, 97, 0.5);
          &:after{left:-10px;width:16px;height:16px;margin-top:-9px;border:2px solid #fe6f61;background-color:#fff;}
        }
      }
    }
    .title{margin-top:10px;color:#ff6f61;font-size:16px;}
    .line{
      position:relative;left:50%;width:100%;height:2px;background-color:#ff6f61;
      &:after{position:absolute;top:0;left:-5px;width:10px;height:10px;margin-top:-4px;background-color:#ff6f61;border-radius:50%;content:'';}
    }
  }
</style>
