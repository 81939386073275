<template>
  <div class="passenger-list" v-if="$parent.$v">
    <passenger-info-item v-for="(item,index) of $parent.$v.passengerInfo.$each.$iter"
                         :order="order"
                         :traveler-list="travelerList"
                         :appellation-options="appellationOptions"
                         :country-options="countryOptions"
                         :area-code-options="areaCodeOptions"
                         :certificate-type-options="certificateTypeOptions"
                         :birthday-options="birthdayOptions"
                         :effective-date-options="effectiveDateOptions"
                         :passenger-list.sync="passengerList"
                         :datas="item"
                         :is-day="isDay"
                         :item-index="Number(index)"
                         @update-list="updatePassengerList"
                         :key="index"/>
  </div>
</template>
<script>
  import {getAllTraveler} from '../../../api/traveler';
  import {getDictList} from '../../../api/common';
  import PassengerInfoItem from './PassengerInfoItem';

  export default {
    name: 'PassengerInfo',
    props: {
      order: Object,
      appellationOptions: Array,
      areaCodeOptions: Array,
      certificateTypeOptions: Array,
      passengerList: Array,
      isDay: Number
    },
    data() {
      return {
        travelerList: [],
        countryOptions: [],
        birthdayOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        effectiveDateOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        }
      };
    },
    components: {PassengerInfoItem},
    created() {
      this.getTravelerList();
      this.getDicts();
    },
    methods: {
      // 更新旅客信息列表
      updatePassengerList(passengerList) {
        this.$emit('update:passengerList', passengerList);
      },
      // 获取旅客信息列表
      getTravelerList() {
        getAllTraveler().then(res => {
          this.travelerList = res.value || [];
        });
      },
      // 获取字典
      getDicts() {
        // 国家/证件签发地
        getDictList('country').then(res => {
          this.countryOptions = res.value || [];
        });
      }
    }
  };
</script>
